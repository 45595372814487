<template>
  <teams></teams>
</template>

<script>
import Teams from '../../components/Teams'

export default {
  components: {
    Teams,
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  },
}
</script>
